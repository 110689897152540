/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  GET_ERROR,
  GET_SUCCESS,
  RESET
} from "@/store/actions/user";

const state = { profile: {} };

const getters = {
  isProfileLoaded: state => !!state.profile.id,
  displayName: state => state.profile.first_name + " " + state.profile.last_name,
  drewRestrictedAccess: state => state.profile.email == "drew@jumpfeed.com"
};

const actions = {
  [GET_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({ url: "https://api-v2.jumpfeed.com/internal/user" })
        .then(resp => {
          commit(GET_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_ERROR);
          dispatch("auth/LOGOUT", null, { root: true }); // if resp is unauthorized, logout too
          reject(err);
        });
    });
  }
};

const mutations = {
  [GET_SUCCESS]: (state, resp) => {
    state.profile = resp.data;
  },
  [GET_ERROR]: state => {
    state.profile = {};
  },
  [RESET]: state => {
    state.profile = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
